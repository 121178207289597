import React from 'react'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import { SectionTitleWrap } from "../../company/join-our-team/join-our-team.stc"
import { AboutSectionWrap, SectionInnerLeft, SectionInnerRight } from './about.stc'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate } from "gatsby"
import Button from "../../../components/shared/button";

//queries the serviceswebdata.json file
const Section = ({ HeadingStyle, SubHeadingStyle, Paragraph, HeadingStyleServies }) => {
    const servicesQueryData = useStaticQuery(graphql`
        query ServicesWebDataQuery {
            serviceswebdataJson(jsonId: {eq: "services-web-content"}) {
                webServices {
                    title
                    classId
                    link
                    description
                    tableHeader
                    tableData
                    image {
                        childImageSharp {
                            fluid(maxWidth: 960, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    } 
                }
            }
        }    
    `)
    // const serviceData = servicesQueryData.serviceswebdataJson;
    // const webServices = serviceData.webServices;
    // console.log(serviceData.webServices)

    // const webContent = webServices.map((webService, index) =>
    //     <div className="col-1 offset-1" key={index}>
    //         <a style={{ color: "black" }} href={webService.link}>{webService.title}</a>
    //     </div>
    // );

    // const content = webServices.map((webService, index) =>
    //     <div className="row services" key={index} id={webService.classId}>
    //         <div className="col-2 offset-1">
    //             <SectionInner style={{ paddingRight: "10vh" }}>
    //                 <SectionTitleWrap>
    //                     <Heading {...HeadingStyleServies}>{webService.title}</Heading>
    //                     {webService.description.map((textData, i) => <Text key={`story-text-${i}`} {...Paragraph}>{textData}</Text>)}
    //                 </SectionTitleWrap>
    //             </SectionInner>
    //         </div>
    //         <div className="col-2">
    //             <SectionInner>
    //                 {webService.image ?
    //                     <Fade right>
    //                         <img src={webService.image.childImageSharp.fluid.src} alt={webService.title} />
    //                     </Fade>
    //                     :
    //                     <Fade right>
    //                         <SectionTitleWrap>
    //                             {webService.tableHeader && <Heading {...SubHeadingStyle}>{webService.tableHeader}</Heading>}
    //                             <div className="row">
    //                                 {webService.tableData.map((textData, i) =>
    //                                     <div className="col-1" key={`story-text-${i}`}>
    //                                         <Text {...Paragraph}>{textData}</Text>
    //                                     </div>)
    //                                 }
    //                             </div>
    //                         </SectionTitleWrap>
    //                     </Fade>
    //                 }

    //             </SectionInner>
    //         </div>
    //     </div>
    // );

    return (
        <AboutSectionWrap>
            <div className="row service-top-section">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading  {...HeadingStyle}>App <br></br> Development - <br></br>Web and Mobile</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage className="service-top-section-image" src={"../../../../src/data/images/webmobdev.jpeg"} alt="web development header"/>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading className="full-engineering" {...HeadingStyleServies}>Full-stack <br></br> engineering <br></br> for every Project</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h3>
                            Software Craftsmanship
                        </h3>
                        <Text {...Paragraph}>
                            We develop high quality, robust code to run your applications. Apps that work each and every time.
                            Engineering software and design to match the user experience and user interface.
                        </Text>
                        <Button
                            onClick={() => { navigate("/contact") }}
                            layout={2}
                            style={{ height: "100%", margin: "auto", backgroundColor: 'green', color: 'white' }}
                        >
                            Contact Us
                            <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft style={{ paddingBottom: 230, paddingRight: 70 }}>
                    <SectionTitleWrap className="technology-system">
                        <Heading {...HeadingStyleServies}>Technology Systems Design</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h3>
                            Systems Architecture
                        </h3>
                        <Text {...Paragraph}>
                            Experts in designing and architecting technology solutions - ask us how today. <br></br><br></br>
                            From operating systems, backend and frontend, programming languages, frameworks, modules and dependencies we known them well.<br></br> <br></br>
                            Our teams have years of experience integrating and developing across the end to end stack.
                            We can evaluate options based on cost, time to market, implementation risk, performance, scaling, and security considerations.
                        </Text>
                        <Button
                            onClick={() => { navigate("/contact") }}
                            layout={2}
                            style={{ height: "100%", margin: "auto", backgroundColor: 'blue', color: 'white' }}
                        >
                            Ask How
                            <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft style={{ paddingBottom: 280, paddingRight: 70 }}>
                    <SectionTitleWrap className="frontend-web">
                        <Heading  {...HeadingStyleServies}>Frontend Web Development</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h3>
                            We build fast, flexible and attractive frontends
                        </h3>
                        <Text {...Paragraph}>
                            Lean on us to develop your frontends. <br></br><br></br>
                            <ul>
                                <li>React.js</li>
                                <li>Javascript</li>
                                <li>Typescript</li>
                                <li>Angular</li>
                                <li>React Native</li>
                                <li>Python</li>
                                <li>PHP</li>
                                <li>HTML5</li>
                                <li>CSS</li>
                                <li>SPAs</li>
                                <li>PWAs</li>
                            </ul>
                            <Button
                                onClick={() => { navigate("/contact") }}
                                layout={2}
                                style={{ height: "100%", margin: "auto", backgroundColor: 'darkorange', color: 'white' }}
                            >
                                Consult Us
                                <i className="ti-arrow-right"></i>
                            </Button>
                        </Text>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft style={{ paddingBottom: 280 }}>
                    <SectionTitleWrap className="react-js">
                        <Heading {...HeadingStyleServies}>React.js <br></br> React Native </Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h3>
                            A Modern Framework for New, Modern Development
                        </h3>
                        <Text {...Paragraph}>
                            We develop React Apps for Web and Mobile. With extensive experience in React Apps we can create blazing fast frontends hooked into the latest database and 3rd party services to power your apps. <br></br><br></br>
                            <ul>
                                <li>React Hooks</li>
                                <li>Redux</li>
                                <li>React Router</li>
                                <li>Material UI</li>
                                <li>Typescript</li>
                                <li>Style Sheets</li>
                                <li>Axios</li>
                            </ul>

                            <Button
                                onClick={() => { navigate("/contact") }}
                                layout={2}
                                style={{ height: "100%", margin: "auto", backgroundColor: '#03a89e', color: 'white' }}
                            >
                                Try Us
                                <i className="ti-arrow-right"></i>
                            </Button>
                        </Text>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft style={{ paddingBottom: 140 }}>
                    <SectionTitleWrap>
                        <Heading className="backend-dev" {...HeadingStyleServies}>Backend <br></br>Development </Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h3>
                            Developing Robust and Scalable Backends to Power Your Apps
                        </h3>
                        <Text {...Paragraph}>
                            We carefully construct the backend to lift heavy, scale and last the test of time. Knowing how to match the right backend to the right specifications is vital and this comes with deep experience and research and development in technologies. <br></br><br></br>
                            <Button
                                onClick={() => { navigate("/contact") }}
                                layout={2}
                                style={{ height: "100%", margin: "auto", backgroundColor: "#4115B7", color: "white" }}
                            >
                                Learn More
                                <i className="ti-arrow-right"></i>
                            </Button>
                        </Text>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft style={{ paddingBottom: 300, paddingRight: 100 }}>
                    <SectionTitleWrap className="backend-infra" >
                        <Heading {...HeadingStyleServies}>Backend <br></br>Infrastructure and Services </Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h3>
                            We understand databases, middleware and 3rd party services / APIs.
                        </h3>
                        <Text {...Paragraph}>
                            <ul>
                                <li>Google Cloud Platform</li>
                                <li>Amazon Web Services</li>
                                <li>Microsoft Azure</li>
                                <li>Firebase</li>
                                <li>DynamoDB</li>
                                <li>MongoDB</li>
                                <li>Postgres</li>
                                <li>MySQL</li>
                                <li>NoSQL</li>
                                <li>Oracle</li>
                                <li>SAP Data Services</li>
                                <li>Cloud Functions</li>
                                <li>Lambda</li>
                                <li>S3</li>
                                <li>Cloud Storage</li>
                                <li>And Much More</li>
                            </ul>
                            <Button
                                onClick={() => { navigate("/contact") }}
                                layout={2}
                                style={{ height: "100%", margin: "auto", backgroundColor: "green", color: "white" }}
                            >
                                Talk Now
                                <i className="ti-arrow-right"></i>
                            </Button>
                        </Text>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft style={{ paddingBottom: 60 }}>
                    <SectionTitleWrap className="assurance-test" >
                        <Heading  {...HeadingStyleServies}>Quality <br></br>Assurance and <br></br> Testing </Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h3>
                            Launch with Confidence with Strongly Tested Code
                        </h3>
                        <Text {...Paragraph}>
                            Discover problems before your customers get to them. We run code on various platforms and staging environments to ensure your platform is road tested before your first customers touch it <br></br><br></br>
                            <Button
                                onClick={() => { navigate("/contact") }}
                                layout={2}
                                style={{ height: "100%", margin: "auto", backgroundColor: 'blue', color: 'white' }}
                            >
                                Learn More
                                <i className="ti-arrow-right"></i>
                            </Button>
                        </Text>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft style={{ paddingBottom: 60 }}>
                    <SectionTitleWrap>
                        <Heading className="security-comp" {...HeadingStyleServies}>Security <br></br>and <br></br> Compliance </Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h3>
                            Security Matters
                        </h3>
                        <Text {...Paragraph}>
                            In today's environment, it matters to have secure and robust apps. Building your apps on the cloud enables us to trust in global giants to secure critical infrastructure allowing us to focus on developing your software to ensure it meets high standards of security.  <br></br><br></br>
                            <Button
                                onClick={() => { navigate("/contact") }}
                                layout={2}
                                style={{ height: "100%", margin: "auto", backgroundColor: 'darkorange', color: 'white' }}
                            >
                                Learn More
                                <i className="ti-arrow-right"></i>
                            </Button>
                        </Text>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>


            {/*{content}*/}

        </AboutSectionWrap>
    )
}
Section.propTypes = {
    HeadingStyle: PropTypes.object,
    SubHeadingStyle: PropTypes.object,
    Paragraph: PropTypes.object,
    HeadingStyleServies: PropTypes.object,
    WebServiceLeft: PropTypes.object
}

Section.defaultProps = {
    HeadingStyle: {
        fontSize: '60px',
        texttransform: 'capitalize',
        lineHeight: '60px',
        color: '#e4e4e4',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px',
            }
        }
    },
    SubHeadingStyle: {
        fontSize: '30px',
        lineHeight: '30px',
        color: 'primary',
        fontweight: 300,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    Paragraph: {
        color: 'black',
        fontSize: '20px'
    },
    HeadingStyleServies: {
        fontSize: '50px',
        texttransform: 'capitalize',
        lineHeight: '50px',
        color: 'primary',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px',
            }
        }
    }
}

export default Section

