import React from "react"
import SEO from '../../components/seo'
import Layout from '../../containers/layout/layout'
import Section from '../../containers/services/web-development'

export default () => (
    <Layout headerLayout={2}>
        <SEO title="Atlasopen - Web and Mobile App Development" description="We develop custom Web and Mobile App"/>
        {/*<Banner/>*/}
        {/*<About/>*/}
        <Section/>
        {/*<JoinOurTeam/>*/}
        {/*<Testimonial/>*/}
    </Layout>
)

